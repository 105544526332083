
h3 {
  font-size: 24px; /* Larger text size */
  font-weight: bold;
  font-family: Georgia;
  color: #333; /* Dark text color */
}

p {
  font-size: 18px;
  font-weight: normal;
  color: #333;
  text-decoration: none;
  font-family: Georgia;
}

.video-container {
  background-color: rgb(254, 255, 188);
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden; 
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  object-fit: cover; 
  object-position: center center; 
}

.info-section {
  background-color: rgb(254, 255, 188);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inner-card {
  height: 70%;
  width: 70%;
  margin-top: 4em;
  padding: 20px;
  background-color: #fffce8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.centered-content {
  margin: 8em 0 4em 0;
}

.instagram-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
  z-index: 1; /* Ensures it's clickable */
}

.icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: Georgia;
  color: #333;
  margin: 0;
}

p {
  font-size: 18px;
  font-weight: normal;
  color: #333;
  text-decoration: none;
  font-family: Georgia;
}

/* MOBILE styling */
@media (max-width: 768px) {
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .video-container video {
    object-fit: cover;
    object-position: center center; 
  }

  .inner-card {
    height: 60%;
  }

  .icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}
